
.table{
  width: 100%;
  min-width: 400px;
  text-align: left;
  border-collapse: collapse; 
  box-sizing: border-box;
  margin-top: 40px;
  border: 1px solid rgba(224, 224, 224, 1);
  color: rgba(0, 0, 0, 0.87);
  font-family: "Lato","Helvetica","Arial",sans-serif;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.table th{
  padding: 20px 15px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  font-weight: 500;
}

.table th::before{
  content: '| ';
  color:rgba(224, 224, 224, 1) ;
}

.table th:first-child:before {
    content: '';
}
.table td{
   padding: 10px 15px;
}
.table tr{
   border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.btnAcao{
  border: none;
  background: inherit;
  padding: 0 5px;
}

/* Modal Edit */

.wrapper_modal_edit{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 130px;
  display: none;
}

.wrapper_modal_edit.active{
  display: flex;
}

.wrapper_modal_edit::before{
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.wrapper_modal_edit .container_modal{
  max-width: 430px;
  position: relative;
  background: #fff;
  z-index: 1;
  display: grid;
  align-items: center;
  grid-gap: 50px;
  padding: 50px 50px 50px 50px;
  box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07);
}

.wrapper_modal_edit .container_modal .form-group{
  width: 300px;
  height: 46px;
  position:relative; 
    margin-top: 20px;
}

.wrapper_modal_edit .container_modal .form-group input{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
   padding: 10px;
  background: transparent;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  font-size: 1rem;
}

 .wrapper_modal_edit .container_modal .form-group span{
  position: absolute;
  top: 1px;
  left: -15px;
  padding: 10px;
  pointer-events: none;
  display: inline-block;
    transition: 0.5s;
} 

.wrapper_modal_edit .container_modal .form-group input:focus ~ span,
.wrapper_modal_edit .container_modal .form-group input:valid ~ span
{
  transform: translateX(-10px) translateY(-32px);
  font-size: .85rem;
}
.wrapper_modal_edit .container_modal .form-group .btn_modal{
  padding: 10px 25px;
  background: #1976d2;
  border: none;
  border-radius: 4px;
  color: #fff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.wrapper_modal_edit .container_modal .form-group .btn_modal:hover{
  background:#106bc4;
  transition: .3s;
}
.wrapper_modal_edit .container_modal .close_modal{
  position: absolute;
  top: 20px;
  right: 20px;

  width: 30px;
  height: 30px;
  border:none;
  border-radius: 50%;
  
}

/* Dialog */

.wrapper_dialog{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 130px;
  display: none;
}

.wrapper_dialog.active{
  display: flex;
}

.wrapper_dialog::before{
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.wrapper_dialog .container_dialog{
  max-width: 430px;
  position: relative;
  background: #fff;
  z-index: 1;
  display: grid;
  align-items: center;
  grid-gap: 50px;
  padding: 50px 50px 50px 50px;
  box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07);
}

.wrapper_dialog .container_dialog .content_dialog{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper_dialog .container_dialog .form-group{
  width: 300px;
  height: 46px;
  position:relative; 
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.wrapper_dialog .container_dialog .form-group input{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
   padding: 10px;
  background: transparent;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  font-size: 1rem;
}

 .wrapper_dialog .container_dialog .form-group span{
  position: absolute;
  top: 1px;
  left: -15px;
  padding: 10px;
  pointer-events: none;
  display: inline-block;
    transition: 0.5s;
} 

.wrapper_dialog .container_dialog .form-group input:focus ~ span,
.wrapper_dialog .container_dialog .form-group input:valid ~ span
{
  transform: translateX(-10px) translateY(-32px);
  font-size: .85rem;
}
.wrapper_dialog .container_dialog .form-group .btn_dialog{
  padding: 10px 15px;
  background: #1976d2;
  border: none;
  border-radius: 4px;
  color: #fff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.wrapper_dialog .container_dialog .form-group .btn_dialog:hover{
  background:#106bc4;
  transition: .3s;
}
.wrapper_dialog .container_dialog .close_dialog{
  position: absolute;
  top: 20px;
  right: 20px;

  width: 30px;
  height: 30px;
  border:none;
  border-radius: 50%;
  
}


@media (min-width: 200px) and (max-width: 600px) {
   table{
     display: block;
    overflow-x: auto;
    white-space: nowrap;
   }
}