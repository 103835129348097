.total_debtors{
 margin: 40px 0;
}

.titulo_table{
  margin-top: 20px;
}

.container_filtro{
  display: flex;
  justify-content: space-between;
  align-items: center;
}