@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.home,
.employee,
.customers,
.payment_customer,
.payment_employeed,
.calendar,
.products,
.divergences {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  /* height: 90vh; */
  align-items: center;
  justify-content: center;
  /*font-size: 3rem;*/
}

.container_geral {
  height: 400px;
  width: 100%;
  max-width: 1150px;
  padding: 0 15px;
}

.weekday {
  width: 50px;
  height: 50px;
  border-width: 2px;
  border-color: black;
  border-style: solid;
  display: inline-block;
  border-radius: 1000px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  position: relative;
  margin-right: 5px;
}
.weekday2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}
