.container_table{
  width: 100%;
  min-width: 400px;
  text-align: left;
  border-collapse: collapse; 
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 20px;
  font-family: "Lato","Helvetica","Arial",sans-serif;
  border: 1px solid rgba(224, 224, 224, 1);

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.container_table button{
  padding: 10px 10px;
  border: none;
  background: none;
}

.container_table th::before{
  content: '| ';
  color:rgba(224, 224, 224, 1) ;
  margin-right: 2px;
}

.container_table th:first-child:before {
    content: '';
}

